import './ContactForm.css'
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm("mbjevzlk");
  if (state.succeeded) {
      return <p>Thanks for your message! I will get back to you as soon as possible.</p>;
  }
  return (
      <form className="contact-form" onSubmit={handleSubmit}>
      <label htmlFor="name">
        Name
      </label>
      <br></br>
      <input
        placeholder='Name'
        id="name"
        type="name" 
        name="name"
      />
      <ValidationError 
        prefix="Name" 
        field="name"
        errors={state.errors}
      />
      <br></br>
      <label htmlFor="email">
        Email
      </label>
      <br></br>
      <input
        placeholder="Email"
        id="email"
        type="email" 
        name="email"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <br></br>
      <label htmlFor="message">
        Message
      </label>
      <br></br>
      <textarea
        placeholder='Message'
        id="message"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      <br></br>
      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}

export default ContactForm
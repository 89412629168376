import {BsCodeSlash} from 'react-icons/bs'
import './Overlay.css'

function Overlay(props) {
    function openPage() {
        window.open("https://www.linkedin.com/in/ziqi-zou/", "_blank", "noreferrer")
    }
    
    return (
        <div className='overlay-main'>
            <div className='overlay-left'>
                <BsCodeSlash />
                Ziqi Zou
            </div>
            <div className='overlay-right' onClick={() => openPage()}>
                Connect on Linkedin
            </div>
        </div>
    )
}

export default Overlay
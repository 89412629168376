import Main from "./components/main/Main.js";
function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;

import './CoverPage.css'
import Scene from './Scene'

function CoverPage(props) {
    return (
        <div className='cover-page-main'>
            <div className='cover-page-left'>
                <div className='cover-page-left-header'>
                    Full Stack Software Engineer
                </div>
                <div className='cover-page-left-detail'>
                <h1>I am a software engineer with a passion for both designing sleek and intuitive front end systems and developing readable, efficient and scalable back end code.</h1>
                <h1>My interest in both logical and creative thinking guided me over from my previous career in public accounting. Having worked as a CPA for several years prior to my transition, I bring with me a problem solving mentality, keen attention to detail, as well as the ability to clearly articulate ideas, contribute in a team environment and manage tight deadlines.</h1>
                </div>

            </div>
            <div className='cover-page-right'>
                <Scene />
            </div>
        </div>
    )
}

export default CoverPage
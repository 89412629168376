import js from '../../assets/js-bw.svg'
import jsColored from '../../assets/js-colored.svg'
import react from '../../assets/react-bw.svg'
import reactColored from '../../assets/react-colored.svg'
import node from '../../assets/node-bw.svg'
import nodeColored from '../../assets/node-colored.svg'
import html from '../../assets/html-bw.svg'
import htmlColored from '../../assets/html-colored.svg'
import css from '../../assets/css-bw.svg'
import cssColored from '../../assets/css-colored.svg'
import ruby from '../../assets/ruby-bw.svg'
import rubyColored from '../../assets/ruby-colored.svg'
import rails from '../../assets/rails-bw.svg'
import railsColored from '../../assets/rails-colored.svg'
import python from '../../assets/python-bw.svg'
import pythonColored from '../../assets/python-colored.svg'
import postgresql from '../../assets/postgresql-bw.svg'
import postgresqlColored from '../../assets/postgresql-colored.svg'
import mongo from '../../assets/mongo-bw.svg'
import mongoColored from '../../assets/mongo-colored.svg'
import git from '../../assets/git-bw.svg'
import gitColored from '../../assets/git-colored.svg'
import heroku from '../../assets/heroku-bw.svg'
import herokuColored from '../../assets/heroku-colored.svg'
import './Skills.css'
import { useState } from 'react'

function Skills(props) {
    const [hovered, setHovered] = useState(-1);
    return (
        <div className='skills-main'>
            <div className='skills-header'>
                Skills & Tech Stack
            </div>
            <div className='skills-detail'>
                I'm knowledgable in a wide range of technologies which I've put to use in my personal and team projects.
                I am also constantly upskilling myself out of a curiosity to dig deeper.
            </div>
            <div className='skills-icon-container'>
                <div className='skills-icon-container-line-one'>
                    <div className='individual-skill' onMouseEnter={() => setHovered(0)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 0 ? jsColored : js}></object>
                        <div className='individual-skill-name'>Javascript</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(1)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 1 ? reactColored : react}></object>
                        <div className='individual-skill-name'>React</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(2)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 2 ? nodeColored : node}></object>
                        <div className='individual-skill-name'>Node</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(3)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 3 ? htmlColored : html}></object>
                        <div className='individual-skill-name'>HTML</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(4)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 4 ? cssColored : css}></object>
                        <div className='individual-skill-name'>CSS</div>
                    </div>
                </div>
                <div className='skills-icon-container-line-two'>
                    <div className='individual-skill' onMouseEnter={() => setHovered(5)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 5 ? rubyColored : ruby}></object>
                        <div className='individual-skill-name'>Ruby</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(6)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 6 ? railsColored : rails}></object>
                        <div className='individual-skill-name'>Rails</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(7)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 7 ? pythonColored : python}></object>
                        <div className='individual-skill-name'>Python</div>
                    </div>
                </div>
                <div className='skills-icon-container-line-three'>
                    <div className='individual-skill' onMouseEnter={() => setHovered(8)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 8 ? postgresqlColored : postgresql}></object>
                        <div className='individual-skill-name'>PostgreSQL</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(9)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 9 ? mongoColored : mongo}></object>
                        <div className='individual-skill-name'>MongoDB</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(10)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 10 ? herokuColored : heroku}></object>
                        <div className='individual-skill-name'>Heroku</div>
                    </div>
                    <div className='individual-skill' onMouseEnter={() => setHovered(11)} onMouseLeave={() => setHovered(-1)}>
                        <object data={hovered === 11 ? gitColored : git}></object>
                        <div className='individual-skill-name'>Git</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills
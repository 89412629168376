import './Contact.css'
import { CiLocationArrow1 } from 'react-icons/ci'
import { TfiEmail } from 'react-icons/tfi'
import { BsTelephone } from 'react-icons/bs'
import { useState } from 'react'
import ContactForm from './ContactForm'
import { BsArrowReturnRight, BsArrowReturnLeft } from 'react-icons/bs'

function Contact(props) {
    const [showForm, setShowForm] = useState(false)
    function handleClick(e) {
        setShowForm(!showForm)
    }

    return (
        <>
        <div className='contact-me-header'>
            Contact Me
        </div>
        <div className='contact-me-details'>
            <div className='location-card'>
                <div className='contact-icon'><CiLocationArrow1 /></div>
                <h1>Location</h1>
                <h2>Bay Area, CA</h2>
            </div>
            <div className='email-card'>
                {!showForm && (
                    <>
                        <div className='contact-icon'><TfiEmail /></div>
                        <h1>Email</h1>
                        <h2 className='contact-email' onClick={handleClick}>brianzou1@gmail.com</h2>
                        <div className='arrow-icon' onClick={handleClick}><BsArrowReturnRight /></div>
                    </>
                )}
                {showForm && (
                    <>
                        <ContactForm />
                        <div className='arrow-icon' onClick={handleClick}><BsArrowReturnLeft /></div>
                    </>
                )}
            </div>
            <div className='phone-card'>
                <div className='contact-icon'><BsTelephone /></div>
                <h1>Phone</h1>
                <h2>+1 (925)-819-1951</h2>
            </div>
        </div>
        </>
    )
}

export default Contact
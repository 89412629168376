import Overlay from '../overlay/Overlay'
import CoverPage from '../cover-page/CoverPage'
import Skills from '../skills/Skills'
import Projects from '../projects/Projects'
import Contact from '../contact/Contact'
import './Main.css'

function Main(props) {
    return (
        <>
        <Overlay />
        <section className='section'>
            <CoverPage />
        </section>
        <section className='section'>
            <Skills />
        </section>
        <section className='section'>
            <Projects />
        </section>
        <section className='section'>
            <Contact />
        </section>
        </>
    )
}

export default Main
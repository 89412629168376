import './Projects.css'
import { SiGithub } from 'react-icons/si'
import { BsPower } from 'react-icons/bs'
import jsProject from '../../assets/last-one-standing.png'
import fsProject from '../../assets/notion-screenshot.png'
import fnProject from '../../assets/grubglobe-screenshot.png'

function Projects(props) {
    function openPage(url) {
        window.open(url, "_blank", "noreferrer")
    }
    
    return (
        <>
        <div className='projects-header'>
            Projects & Featured Work
        </div>
        <div className='projects-container'>
            <div className='individual-project'>
                <div className='individual-project-img'>
                    <img src={jsProject}></img>
                </div>
                <div className='individual-project-title'>
                    Last One Standing
                </div>
                <div className='individual-project-detail'>
                    <h1>A survival role-playing game set in a post-apocalyptic world.</h1>
                    <h1>
                        Last One Standing was developed using Javascript and vanilla DOM manipulation.
                        As the main character, you wake up one day to find a deadly virus has swept through your town. Holding on to the last glimmer of hope, you pick up your bow and arrow and set out to find other survivors.
                    </h1>
                    <h1>
                        Languages & Libraries: Javascript, HTML, CSS, THREE.js
                    </h1>
                </div>
                <div className='individual-project-footer'>
                    <div className='individual-project-footer-item' onClick={() => openPage("https://github.com/ziqi23/Last-One-Standing")}>
                        <SiGithub />Code
                    </div>
                    <div className='individual-project-footer-item' onClick={() => openPage("https://ziqi23.github.io/Last-One-Standing/")}>
                        <BsPower />Live Site
                    </div>
                </div>
            </div>
            <div className='individual-project'>
                <div className='individual-project-img'>
                    <img src={fsProject}></img>
                </div>
                <div className='individual-project-title'>
                    Zotion
                </div>
                <div className='individual-project-detail'>
                    <h1>Clone of the productivity and note-taking web application Notion.</h1>
                    <h1>Zotion is a space for you to write and store all your notes. This full-stack application features a minimalistic and intuitive front-end design as well as additional functionalities such as rich text editing and dynamic content search.</h1>
                    <h1>
                        Languages & Libraries: Javascript, React, Redux, Ruby, HTML, CSS, Ruby, Rails, PostgreSQL.
                    </h1>
                </div>
                <div className='individual-project-footer'>
                    <div className='individual-project-footer-item' onClick={() => openPage("https://github.com/ziqi23/Zotion")}>
                        <SiGithub />Code
                    </div>
                    <div className='individual-project-footer-item' onClick={() => openPage("https://zotion-2ffb3968ded2.herokuapp.com/")}>
                        <BsPower />Live Site
                    </div>
                </div>
            </div>
            <div className='individual-project'>
                <div className='individual-project-img'>
                    <img src={fnProject}></img>
                </div>
                <div className='individual-project-title'>
                    GrubGlobe
                </div>
                <div className='individual-project-detail'>
                    <h1>Social, interactive, and AI-augmented full stack app for exploring diverse recipes from around the world</h1>
                    <h1>GrubGlobe provide a way for foodies to discover their next adventure. The application was developed in collaboration alongside several other engineers using a MERN stack. Follow along recipes, track nutrition data, earn badges and achievements, all with the help of your personal AI assistant!</h1>
                    <h1>
                        Languages & Libraries: Javascript, React, Redux, Express, Node, MongoDB.
                    </h1>
                </div>
                <div className='individual-project-footer'>
                    <div className='individual-project-footer-item' onClick={() => openPage("https://github.com/ziqi23/Grub-Globe-2")}>
                        <SiGithub />Code
                    </div>
                    <div className='individual-project-footer-item' onClick={() => openPage("https://grubglobe.herokuapp.com/")}>
                        <BsPower />Live Site
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Projects
import { useEffect, useRef, useState } from "react"
import * as THREE from "three";
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import { FBXLoader } from 'three/addons/loaders/FBXLoader'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import pc from '../../assets/test.fbx'
import monitorTexture from '../../assets/monitor-texture.png'
import desktopTexture from '../../assets/desktop.jpeg'
import iconTexture1 from '../../assets/section1.png'
import iconTexture2 from '../../assets/section2.png'
import iconTexture3 from '../../assets/section3.png'
import iconTexture4 from '../../assets/section4.png'

function Scene(props) {
    const ref = useRef();  
    const [iconHovered, setIconHovered] = useState('')
    useEffect(() => {
        function handleClick(e) {
            const script = document.createElement('script')
            script.innerHTML = `$(document).ready(function() {$(".main").moveTo(${parseInt(iconHovered.slice(-1))})})`
            document.getElementsByTagName('body')[0].appendChild(script)
            setTimeout(() => script.remove(), 500)
            setTimeout(() => setIconHovered(''))
        }
        if (iconHovered) {
            document.addEventListener("click", handleClick);
        }
        return () => document.removeEventListener("click", handleClick)
    }, [iconHovered])

    useEffect(() => {
        async function initializeScene() {
            // Initialize scene
            const scene = new THREE.Scene();
            const camera = new THREE.PerspectiveCamera(45, 1, 0.75, 1000);
            const light = new THREE.DirectionalLight();
            light.position.x = 0;
            light.position.z = 50;
            light.position.y = 25;
            scene.add(light);
        
            // Initialize PC
            const loader = new FBXLoader()
            const texture = new THREE.TextureLoader().load(monitorTexture);

            const obj = await loader.loadAsync(pc)
            obj.scale.x = 0.16;
            obj.scale.y = 0.16;
            obj.scale.z = 0.16;
            obj.rotation.y = -2.8 * Math.PI / 4;
            obj.children[0].material[1] = new THREE.MeshStandardMaterial({map: texture});
            obj.children[0].material[3] = new THREE.MeshStandardMaterial({map: texture});
            obj.children[0].material[2] = new THREE.MeshStandardMaterial({color: 0x018281});
            scene.add(obj);

            // Add desktop icons
            const iconGeometry1 = new THREE.PlaneGeometry(2, 3)
            const iconMaterial1 = new THREE.MeshStandardMaterial();
            const iconMesh1 = new THREE.Mesh(iconGeometry1, iconMaterial1)
            new THREE.TextureLoader().load(iconTexture1, (texture) => {
                iconMaterial1.map = texture
                iconMaterial1.transparent = true;
                iconMesh1.rotation.y = -0.65;
                iconMesh1.rotation.x = -0.2;
                iconMesh1.position.z = -6.5;
                iconMesh1.position.y = 26;
                iconMesh1.position.x = -9.2;
                iconMesh1.name = 'section1'
                scene.add(iconMesh1)
            });
            const iconGeometry2 = new THREE.PlaneGeometry(2, 3)
            const iconMaterial2 = new THREE.MeshStandardMaterial();
            const iconMesh2 = new THREE.Mesh(iconGeometry2, iconMaterial2)
            new THREE.TextureLoader().load(iconTexture2, (texture) => {
                iconMaterial2.map = texture
                iconMaterial2.transparent = true;
                iconMesh2.rotation.y = -0.65;
                iconMesh2.rotation.x = -0.2;
                iconMesh2.position.z = -6;
                iconMesh2.position.y = 22;
                iconMesh2.position.x = -9.5;
                iconMesh2.name = 'section2'
                scene.add(iconMesh2)
            });
            const iconGeometry3 = new THREE.PlaneGeometry(2, 3)
            const iconMaterial3 = new THREE.MeshStandardMaterial();
            const iconMesh3 = new THREE.Mesh(iconGeometry3, iconMaterial3)
            new THREE.TextureLoader().load(iconTexture3, (texture) => {
                iconMaterial3.map = texture
                iconMaterial3.transparent = true;
                iconMesh3.rotation.y = -0.65;
                iconMesh3.rotation.x = -0.2;
                iconMesh3.position.z = -5.5;
                iconMesh3.position.y = 18;
                iconMesh3.position.x = -9.8;
                iconMesh3.name = 'section3'
                scene.add(iconMesh3)
            });
            const iconGeometry4 = new THREE.PlaneGeometry(2, 3)
            const iconMaterial4 = new THREE.MeshStandardMaterial();
            const iconMesh4 = new THREE.Mesh(iconGeometry4, iconMaterial4)
            new THREE.TextureLoader().load(iconTexture4, (texture) => {
                iconMaterial4.map = texture
                iconMaterial4.transparent = true;
                iconMesh4.rotation.y = -0.65;
                iconMesh4.rotation.x = -0.2;
                iconMesh4.position.z = -5;
                iconMesh4.position.y = 14;
                iconMesh4.position.x = -10.1;
                iconMesh4.name = 'section4'
                scene.add(iconMesh4)
            });

        // Add text 
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        context.fillStyle = 'white'
        context.font = '20px sans-serif'

        context.fillText('My Bio', 0, 20)
        const fontTexture = new THREE.Texture(canvas)
        fontTexture.needsUpdate = true
        const fontMaterial = new THREE.MeshBasicMaterial({
            map: fontTexture,
            side: THREE.DoubleSide,
         })
        fontMaterial.transparent = true
        const text = new THREE.Mesh(new THREE.PlaneGeometry(10, 10), fontMaterial)
        text.position.z = -2;
        text.position.y = 22;
        text.position.x = -4;
        text.rotation.y = -0.65;
        text.rotation.x = -0.2;
        text.rotation.z = -0.1;
        scene.add(text)

        const canvas2 = document.createElement('canvas')
        const context2 = canvas2.getContext('2d')
        context2.fillStyle = 'white'
        context2.font = '20px sans-serif'
        context2.fillText('Skills & Tech Stack', 0, 20)
        const fontTexture2 = new THREE.Texture(canvas2)
        fontTexture2.needsUpdate = true
        const fontMaterial2 = new THREE.MeshBasicMaterial({
            map: fontTexture2,
            side: THREE.DoubleSide,
         })
        fontMaterial2.transparent = true
        const text2 = new THREE.Mesh(new THREE.PlaneGeometry(10, 10), fontMaterial2)
        text2.position.z = -0.5;
        text2.position.y = 18.5;
        text2.position.x = -5;
        text2.rotation.y = -0.65;
        text2.rotation.x = -0.2;
        text2.rotation.z = -0.1;
        scene.add(text2)

        const canvas3 = document.createElement('canvas')
        const context3 = canvas3.getContext('2d')
        context3.fillStyle = 'white'
        context3.font = '20px sans-serif'
        context3.fillText('My Projects', 0, 20)
        const fontTexture3 = new THREE.Texture(canvas3)
        fontTexture3.needsUpdate = true
        const fontMaterial3 = new THREE.MeshBasicMaterial({
            map: fontTexture3,
            side: THREE.DoubleSide,
         })
        fontMaterial3.transparent = true
        const text3 = new THREE.Mesh(new THREE.PlaneGeometry(10, 10), fontMaterial3)
        text3.position.z = 0;
        text3.position.y = 14.5;
        text3.position.x = -5.6;
        text3.rotation.y = -0.65;
        text3.rotation.x = -0.2;
        text3.rotation.z = -0.1;
        scene.add(text3)

        const canvas4 = document.createElement('canvas')
        const context4 = canvas4.getContext('2d')
        context4.fillStyle = 'white'
        context4.font = '20px sans-serif'
        context4.fillText('Contact Me', 0, 20)
        const fontTexture4 = new THREE.Texture(canvas4)
        fontTexture4.needsUpdate = true
        const fontMaterial4 = new THREE.MeshBasicMaterial({
            map: fontTexture4,
            side: THREE.DoubleSide,
         })
        fontMaterial4.transparent = true
        const text4 = new THREE.Mesh(new THREE.PlaneGeometry(10, 10), fontMaterial4)
        text4.position.z = 1;
        text4.position.y = 10;
        text4.position.x = -6;
        text4.rotation.y = -0.65;
        text4.rotation.x = -0.2;
        text4.rotation.z = -0.1;
        scene.add(text4)

        // Utilize raycaster to determine which icon is being hovered over
        const raycaster = new THREE.Raycaster();
        const mousePos = new THREE.Vector2();
        let canvasWidth = 800;
        let canvasHeight = 600;
        ref.current.addEventListener('mousemove', handleMouseMoveForRaycaster);
        function handleMouseMoveForRaycaster(e) {
            const rect = e.currentTarget.getBoundingClientRect();
            mousePos.x = ((e.clientX - rect.left) / canvasWidth) * 2 - 1;
            mousePos.y = -((e.clientY - rect.top) / canvasHeight) * 2 + 1;
            raycaster.setFromCamera(mousePos, camera);
            const intersects = raycaster.intersectObjects(scene.children);
            if (intersects[0]) {
                const object = intersects[0].object;
                switch (object?.name) {
                    case 'section1':
                        setIconHovered('section1')
                        document.getElementsByTagName('body')[0].style.cursor = 'pointer'
                        break;
                    case 'section2':
                        setIconHovered('section2')
                        document.getElementsByTagName('body')[0].style.cursor = 'pointer'
                        break;
                    case 'section3':
                        setIconHovered('section3')
                        document.getElementsByTagName('body')[0].style.cursor = 'pointer'
                        break;
                    case 'section4':
                        setIconHovered('section4')
                        document.getElementsByTagName('body')[0].style.cursor = 'pointer'
                        break;
                    default:
                        setIconHovered('')
                        document.getElementsByTagName('body')[0].style.cursor = 'default'
                        break;
                }
            }
        }
        
            // Point camera towards (0, 0, 0)
            camera.position.set(0, 40, 80);
            camera.lookAt(obj.position);
        
            // Render above scene
            const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
            renderer.setSize(800, 600);
            renderer.render(scene, camera);
            ref.current.appendChild(renderer.domElement);

            // Add camera controls
            const controls = new OrbitControls( camera, renderer.domElement );
            controls.autoRotate = true;
            controls.rotateSpeed = 0.5;
            controls.enablePan = false;
            controls.enableDamping = true;
            controls.minDistance = 80;
            controls.maxDistance = 100;
            controls.minPolarAngle = 1 * Math.PI / 3
            controls.maxPolarAngle = 0.9 * Math.PI / 2;
            controls.minAzimuthAngle = 1.8 * Math.PI;
            controls.maxAzimuthAngle = 2 * Math.PI;
            function update() {
                requestAnimationFrame(update);
                controls.update();
                renderer.render(scene, camera);
            }
            update();
        }
        initializeScene();
    }, []);
    
    return (
        <div ref={ref}>
        </div>
    )
}

export default Scene